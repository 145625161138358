import React from "react";
import Layout from "../components/Layout";
import ContentfulRichTech from "../components/ContentfulRichText";
import { Link } from "gatsby";

import Sidebar from "../components/Sidebar";

import { HomeIcon } from "@heroicons/react/solid";
import Newsletter from "../components/Newsletter";
import SocialLinks from "../components/SocialLinks";
import SEO from "../components/SEO";
import { GatsbyImage } from "gatsby-plugin-image";

const SingleArticle = ({ article }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: article.title,
    image: article.image.file.url,
    description: article.description.description,
    author: {
      "@type": "Person",
      name: article.author,
    },
    datePublished: article.date,
  };

  return (
    <Layout>
      <SEO
        title={article.title}
        description={article.description.description}
        image={article.image.file.url}
        slug={article.title
          .toLowerCase()
          .replaceAll(" ", "")
          .replaceAll("'", "-")
          .replaceAll(":", "-")}
      />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      {/* HERO */}
      <div className="hidden lg:block relative bg-e-blue overflow-hidden h-[350px]">
        <div className="absolute inset-0">
          <GatsbyImage
            className="w-full h-full object-cover"
            image={article.image.gatsbyImageData}
            alt=""
          />
          {/* <div
            className="absolute inset-0 bg-e-blue mix-blend-multiply"
            aria-hidden="true"
          /> */}
          <svg
            width="925"
            height="366"
            viewBox="0 0 925 366"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-0 top-0"
          >
            <path
              d="M344.5 0.327271L0 365.327H925V0.327271H344.5Z"
              fill="#1D71B8"
              fillOpacity="0.4"
            />
          </svg>
        </div>
        <div className="relative max-w-7xl mx-auto pt-[310px] px-4 flex justify-end items-end">
          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div>
                  <Link
                    to="/"
                    className="text-white hover:text-e-blue transition"
                  >
                    <HomeIcon
                      className="flex-shrink-0 h-6 w-6"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to="/articles"
                    className="ml-4 text-xl font-medium text-white hover:text-e-blue transition"
                  >
                    Archive
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                  <Link
                    to="#"
                    className="ml-4 text-xl font-medium text-white hover:text-e-blue transition"
                  >
                    {`${article.title.slice(0, 22)}...`}
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* LAYOUT WITH SIDEBAR */}
      <div className="bg-white">
        <div>
          <main className="container mx-auto px-4 my-8 sm:mt-12 sm:px-6 lg:px-8">
            <div className="py-12 flex flex-col flex-col-reverse lg:grid lg:grid-cols-4 lg:gap-x-12 xl:grid-cols-5">
              <div className=" lg:col-span-3 xl:col-span-4 xl:pr-24">
                <div className="pb-12">
                  <h1 className=" mt-4 sm:mt-0 text-3xl leading-8 font-extrabold tracking-tight text-gray-500 sm:text-4xl">
                    {article.title}
                  </h1>
                  <div className="border-b border-e-blue flex justify-between mt-6">
                    <div className="flex flex-col lg:flex-row gap-0 lg:gap-2 text-gray-500 lg:items-center">
                      <p className="pt-0">{article.author}</p>
                      <p className="hidden lg:block text-e-blue font-bold">/</p>
                      <p className="pt-0">{article.date}</p>
                    </div>
                    <div className="border-l border-e-blue py-1 lg:py-4 px-4">
                      <SocialLinks
                        url={`https://edgelegal.com.au/${article.title
                          .toLowerCase()
                          .replaceAll(" ", "-")
                          .replaceAll(":", "-")
                          .replaceAll("'", "-")}/`}
                        title={article.title}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-8 lg:mt-0">
                  <div className="text-base max-w-prose mx-auto lg:max-w-none text-gray-500">
                    <ContentfulRichTech richText={article.content} />
                  </div>
                </div>
              </div>
              <Sidebar />
            </div>
          </main>
        </div>
      </div>

      <Newsletter />
    </Layout>
  );
};

export default SingleArticle;
