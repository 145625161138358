import React from 'react'
import SingleArticle from '../templates/SingleArticle'

import {graphql} from 'gatsby'

const SingleResource = ({data}) => {

  const article = data.contentfulResource;
  
  return (
    <SingleArticle article = {article}/>
  )
}

export const query = graphql`

    query getSingleResource($title: String) {
        contentfulResource(title: {eq: $title}) {
            title
            description {
                description
            }
            content {
                raw
                references {
                    ... on ContentfulAsset {
                        gatsbyImageData(width: 1000, placeholder: BLURRED)
                        description
                        contentful_id
                        __typename
                        title
                        file{
                            url
                        }
                    }
                }
            }
            date(formatString: "DD MMMM YYYY")
            image {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                file {
                    url
                }
            }
            author
            category {
                id
                name
            }
        }
    }
`

export default SingleResource